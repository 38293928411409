<template>
  <v-card
    flat
    class="rounded-lg pa-2 d-flex flex-column justify-space-between"
    height="100%"
  >
    <v-card-title class="text-color-title body-1 font-weight-bold">
      {{ title }}
    </v-card-title>
    <v-card-text
      class="mt-2 d-flex flex-column justify-space-between"
      style="height: 100%"
    >
      <div class="awardees-content">
        <h4 class="text-color-black mb-4">Choose the awardee/s</h4>
        <v-autocomplete
          v-model="selectedUsers"
          :items="users"
          item-value="id"
          item-text="name"
          filled
          multiple
          rounded
          dense
          return-object
          placeholder="Search for names here"
        ></v-autocomplete>
        <div class="awardee-name text-center mb-15 mt-2">
          <v-chip
            class="ma-2"
            color="#d4f1ff"
            v-for="(user, key) of selectedUsers"
            :key="key"
            close
            @click:close="selectedUsers.splice(key, 1)"
          >
            {{ user.name }}
          </v-chip>
        </div>
      </div>
      <h4 class="text-color-black">Choose the badge/s</h4>
      <div class="badges-content">
        <div class="badge-items mt-4 px-4">
          <v-btn
            icon
            v-for="(badge, index) of badges"
            :key="index"
            x-large
            class="badge-btn"
            :class="{ selected: badge.selected }"
            @click="badge.selected = !badge.selected"
          >
            <v-img contain width="60px" class="" :src="badge.url"></v-img>
          </v-btn>
        </div>
      </div>
      <div class="action d-flex justify-end mt-5">
        <v-btn
          class="bg-secondary text-color-white text-capitalize"
          :loading="loading"
          @click="submit()"
        >
          Award Badges
        </v-btn>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { GLOBAL_EVENT_BUS } from "@/services/event_buses/globalEventBus";

import { RepositoryFactory } from "@/services/repositories/repositoryFactory";
const userRepository = RepositoryFactory.get("user");
const badgesRepository = RepositoryFactory.get("badge");
const userBadgesRepository = RepositoryFactory.get("user-badge");

export default {
  data: () => ({
    title: "Awarding of Badges",
    badges: [],
    users: [],
    selectedUsers: [],
    loading: false,
    eventName: "reloadItems_leaderboard"
  }),
  methods: {
    async getUsers() {
      let params = {
        full_data: true
      };

      await userRepository
        .get({ params })
        .then(response => {
          let {
            data: { responseData: items }
          } = response;

          this.users = items.map(item => {
            return {
              id: item.id,
              name: `${item.first_name} ${item.last_name}`
            };
          });
        })
        .catch(() => {
          this.users = [];
        });
    },
    async getBadges() {
      let params = {
        full_data: true
      };

      await badgesRepository
        .get({ params })
        .then(response => {
          let {
            data: { responseData: items }
          } = response;

          this.badges = items.map(item => {
            return {
              id: item.id,
              name: item.name,
              url: item.image_url,
              selected: false
            };
          });
        })
        .catch(() => {
          this.badges = [];
        });
    },
    submit() {
      let form = {};

      form.user_ids = this.selectedUsers.map(item => {
        return item.id;
      });

      form.badge_ids = this.badges
        .filter(item => item.selected)
        .map(item => {
          return item.id;
        });

      let haveSelectedUser = form.user_ids && form.user_ids.length > 0;
      let haveSelectedBadge = form.badge_ids && form.badge_ids.length > 0;

      let valid = haveSelectedUser && haveSelectedBadge;

      if (valid) {
        this.loading = true;

        userBadgesRepository
          .create(form)
          .then(() => {
            GLOBAL_EVENT_BUS.$emit(this.eventName);

            this.MIXINS_SHOW_SNACKBAR(
              "success",
              "Successfully Award Badge(s)."
            );

            this.setToDefault();
          })
          .catch(error => {
            console.log({ error });

            let objectError = error.response.data.errors;

            for (const [key] of Object.entries(objectError)) {
              let errorMessage = objectError[key][0];

              this.MIXINS_SHOW_SNACKBAR("error", errorMessage);

              break;
            }
          })
          .finally(() => {
            this.loading = false;
          });

        return;
      }

      this.MIXINS_SHOW_SNACKBAR("error", "Select User(s) or Badge(s).");

      return;
    },
    setToDefault() {
      this.selectedUsers = [];
      this.badges.map(item => {
        item.selected = false;

        return item;
      });
    }
  },
  mounted() {
    this.getUsers();
    this.getBadges();
  }
};
</script>

<style lang="scss" scoped>
::v-deep .v-autocomplete:not(.v-input--is-focused).v-select--chips input {
  max-height: 26px !important;
}

::v-deep .v-select__selections .v-select__selection {
  display: none !important;
}

.badge-btn {
  margin-right: 20px;
  margin-bottom: 20px;
  filter: grayscale(0.8);

  &.selected {
    filter: grayscale(0);
  }
}
.badges-content {
  height: 300px;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;

  .badge-items {
    width: 700px;
  }
}
</style>
